<script>
    import { axiosAuth, commons } from "../auth.js";
    import { RefreshCwIcon } from "svelte-feather-icons";
    import { toast } from "@zerodevx/svelte-toast";
    import { charts } from "../corpjobperson.js";

    const TUKANGA_URL = window.env ? window.env.TUKANGA_URL : __process.env.TUKANGA_URL;

    let refreshing = false;

    async function refresh() {
        console.log("calling tukanga");

        refreshing = true;
        try {
            const res = await $axiosAuth.post(TUKANGA_URL + "api/orgchart/refreshCache");
            toast.push("Επιτυχία ενημέρωσης");
        } catch {
            toast.push("Σφάλμα στην ενημέρωση οργανογράμματος");
        } finally {
            // refreshing = false;
        }

        try {
            const res = await Promise.all(
            $charts.map( async chart => {
                let url = TUKANGA_URL + "api/orgchart/setRootSupervisor?orgId=" + $commons.orgUuid + "&chartId=" + chart.uuid; 
                let res = await $axiosAuth.post(url);
            }));
            // toast.push("Επιτυχία ενημέρωσης διαχειριστή");
        } catch (error) {
            toast.push("Σφάλμα στην ενημέρωση διαχειριστή");
        } finally {
            refreshing = false;
        }

    };
</script>

{#if TUKANGA_URL}
    <button
        title={"Ενημέρωση του " + TUKANGA_URL}
        on:click={refresh}
        class="button"
        class:is-loading={refreshing}
    >
      <span class="icon">
        <RefreshCwIcon size="24" />
        </span>
    </button>
{/if}
