<script>
  import { params, url, goto } from "@sveltech/routify";
  import { axiosAuth, commons } from "../../../../../auth.js";
  import Unit from "../../_Unit.svelte";
  import Assign from "../../../../../components/Assign.svelte";
  import Select from "svelte-select";
  import {
    removeAccents,
    labelFunction,
  } from "../../../../../removeaccents.js";
  import { people, charts } from "../../../../../corpjobperson.js";
  import Department from "../../../../../components/_Department.svelte";
  import ChartsMenu from "../../../../../components/_ChartsMenu.svelte";
  import { fade, scale, fly } from "svelte/transition";
  import {
    PrinterIcon,
    PlusIcon,
    XIcon,
    Trash2Icon,
    MoreHorizontalIcon,
  } from "svelte-feather-icons";
  import Spinner from "svelte-spinner";
  import moment from "moment";

  // $: console.log($params);

  let deleteMenu = false;
  let deleteConfirmation;

  function closeDeleteMenu() {
    deleteMenu = false;
  }

  $: simplePeople = $people ? $people.map((p) => p.personDto) : [];

  let chart;
  let open = false; //modal for delete confirmation
  let moveUnit;
  let destinationParentUnit;

  //assign stuff
  let openAssign = false;

  let yposition;

  $: if (openAssign) {
    yposition = window.scrollY;
  } else {
    window.setTimeout(function () {
      window.scrollTo(0, yposition);
    }, 100);
  }

  let selectedPeople = [];
  let selectedChart;
  let selectedRole;
  let selectedUnit;

  let selectedDepartment; //way to call update on the Department component
  let openNewUnit = false;
  let newUnitName;
  let newUnitParentUuid;
  let newUnitDisabled;
  let newUnitHasSource = false;
  let newUnitTransferAssignments = true;
  let sourceUnits = [null];

  const resetNewUnit = () => {
    newUnitName = null;
    newUnitHasSource = false;
    newUnitTransferAssignments = true;
    sourceUnits = [null];
  };

  const addSourceUnit = () => {
    let newUnit;
    sourceUnits = [...sourceUnits, null];
  };

  const removeSourceUnit = (unit) => {
    sourceUnits.splice(sourceUnits.indexOf(unit), 1);
    sourceUnits = sourceUnits;
  };

  // $: console.log("chart1", chart);

  // alternative but with 'title' instead of 'name'

  // const res2 = await $axiosAuth.get(
  //       "/v2/" + clientUuid + "/structure/" + $params.chart + "/unittree"
  //     );

  // function filterUuid(uuid, data) {
  //   console.log("help", data);
  //   return data.reduce((arr, item) => {
  //     if (item.uuid != uuid) {
  //       if (item.children) item.children = filterUuid(uuid, item.children);
  //       arr.push(item);
  //     }
  //     return arr;
  //   }, []);
  // }

  async function createNewUnit() {
    openNewUnit = false;
    let newUnit = null;
    try {
      let unitsOfOrigin = [];

      if (newUnitHasSource) {
        unitsOfOrigin = sourceUnits
          .filter((u) => u !== null)
          .map((u) => {
            return { sourceUnitUuid: u.uuid, dateOfTransfer: moment() };
          });
        // alert(JSON.stringify(unitsOfOrigin));
      }

      const res = await $axiosAuth.post(
        "/" + clientUuid + "/structure/" + $params.chart + "/department",
        {
          parentUuid: newUnitParentUuid,
          validFrom: "",
          validUntil: "",
          departmentDto: {
            name: newUnitName || "Νέα οργανωτική μονάδα",
            unitsOfOrigin: unitsOfOrigin,
            postalCode: "",
            description: "",
            phone: "",
            fax: "",
            email: "",
            orderNo: 0,
            abbreviation: "",
            city: "",
            address: "",
            disabled: newUnitDisabled,
          },
        },
      );
      newUnit = await res.data.departmentDto;
      return newUnit;
    } catch (error) {
      throw new Error(error);
      alert(error);
    }
  }

  async function transferAssignments(newUnit) {
    let sourceUnitsIds = sourceUnits
      .filter((u) => u !== null)
      .map((u) => u.uuid);

    try {
      if (
        newUnitHasSource &&
        newUnitTransferAssignments &&
        sourceUnitsIds.length
      ) {
        let url = "/" + clientUuid + "/structure/assignments/transfer?";
        for (let i = 0; i < sourceUnitsIds.length; i++) {
          url += "sourceUnitUuid=" + sourceUnitsIds[i] + "&";
        }

        url += "targetUnitUuid=" + newUnit.uuid;

        // alert(url);

        const res = await $axiosAuth.put(url);
      }
    } catch (error) {
      throw new Error(error);
      alert(error);
    } finally {
      return newUnit;
    }
  }

  async function addNewUnit() {
    try {
      createNewUnit()
        .then(transferAssignments)
        .then((newUnit) => {
          if (newUnit && newUnit.uuid) {
            $goto("/structure/:chart/department/:department", {
              chart: $params.chart,
              department: newUnit.uuid,
            });
            resetNewUnit();
          }
        });
    } catch (error) {
      throw new Error(error);
      alert(error);
    }
  }

  const cancelNewUnit = () => (openNewUnit = false);

  const askForNewUnit = (e) => {
    // alert(JSON.stringify(e.detail));
    newUnitParentUuid = e.detail.parentUuid;
    newUnitDisabled = e.detail.disabled;
    openNewUnit = true;
  };

  async function deleteChart() {
    try {
      // /{clientUuid}/structure/chart/{orgChartUuid}
      const res = await $axiosAuth.delete(
        "/" + $commons.orgUuid + "/structure/chart/" + $params.chart,
      );
      $goto("/structures");
      $charts = $charts.filter(function (c) {
        return c.uuid != $params.chart;
      });
    } catch (error) {
      alert(JSON.stringify(error));
    } finally {
      cancelDeleteChart();
    }
  }

  async function deleteDepartment() {
    // /{clientUuid}/structure/{orgChartUuid}/department
    try {
      const res = await $axiosAuth.delete(
        "/" +
          $commons.orgUuid +
          "/structure/" +
          $params.chart +
          "/department?divisionUuid=" +
          $params.department,
      );
    } catch (error) {
    } finally {
      open = false;
      $goto("/structure/" + $params.chart + "/department/all");
    }
  }

  function deleteUnit() {
    let deleteUuid = $params.department;
    if (chart.uuid === deleteUuid) {
      console.log("deleting the whole chart!");
      chart = [];
    } else {
      chart.children = filterUuid(deleteUuid, chart.children);
      chart = chart;
    }
    open = false;
  }

  // v2/{clientUuid}/assignments/charts/{chartUuid}/unit/{unitUuid}/job/{jobUuid}/person/{personUuid}

  let disableAdd = true;

  $: if (
    selectedChart &&
    selectedRole &&
    selectedUnit &&
    selectedPeople &&
    selectedPeople.length
  ) {
    disableAdd = false;
  } else {
    disableAdd = true;
  }

  let assigning = false;

  async function assign() {
    if (
      selectedChart.uuid &&
      selectedUnit.uuid &&
      selectedRole.uuid &&
      clientUuid
    ) {
      assigning = true;

      let promises = selectedPeople
        .filter((p) => p && p.uuid && p.uuid.length)
        .map((p) =>
          $axiosAuth.put(
            "/v2/" +
              clientUuid +
              "/assignments/charts/" +
              selectedChart.uuid +
              "/unit/" +
              selectedUnit.uuid +
              "/job/" +
              selectedRole.uuid +
              "/person/" +
              p.uuid,
            {
              clientUuid: clientUuid,
              corporateUuid: selectedUnit.uuid,
              jobDescriptionUuid: selectedRole.uuid,
              orgChartUuid: selectedChart.uuid,
              personUuid: p.uuid,
            },
          ),
        );

      try {
        const res = await Promise.all(promises);
      } catch (error) {
        alert(JSON.stringify(error));
      } finally {
        openAssign = false;
        assigning = false;
        // rerender department...
        selectedDepartment.rerender();
      }
    } else {
      alert(
        "Εντοπίστηκε σφάλμα στην καταχώριση. Παρακαλώ ελέγξτε τα πεδία που έχετε συμπληρώσει.",
      );
    }
  }

  function cancelAssign() {
    openAssign = false;
  }

  function openAssignDialog(event) {
    selectedChart = event.detail.chart || null;
    selectedRole = event.detail.role || null;
    selectedUnit = event.detail.unit || null;
    selectedPeople = event.detail.person ? [event.detail.person] : [];
    openAssign = true;
  }

  function cancel() {
    open = false;
  }

  let openRearrange = false;
  let rearranging = false;

  function openRearrangeDialog() {
    moveUnit = null;
    destinationParentUnit = null;
    openRearrange = true;
  }

  function cancelRearrange() {
    openRearrange = false;
  }

  function findParentOfUnit(uuid, chart) {
    let result = null;
    if (chart.children && chart.children.length) {
      for (let i = 0; i < chart.children.length; i++) {
        if (uuid == chart.children[i].uuid) {
          result = chart;
          break;
        }
      }

      if (!result) {
        for (let i = 0; i < chart.children.length; i++) {
          result = findParentOfUnit(uuid, chart.children[i]);
          if (result) break;
        }
      }
    }
    return result;
  }

  function findUnit(uuid, chart) {
    let result = null;
    if (chart.uuid == uuid) result = chart;
    else {
      if (chart.children && chart.children.length) {
        for (let i = 0; i < chart.children.length; i++) {
          result = findUnit(uuid, chart.children[i]);
          if (result) break;
        }
      }
    }
    return result;
  }

  async function rearrange() {
    rearranging = true;

    console.log("moveUnit:", moveUnit);
    // console.log("destinationParentUnit:", destinationParentUnit);

    if (chart) {
      let myParentOfMoveUnitRef = findParentOfUnit(moveUnit.uuid, chart);
      let myMoveUnitRef = findUnit(moveUnit.uuid, chart);
      let myDestinationParentUnitRef = findUnit(
        destinationParentUnit.uuid,
        chart,
      );
      // console.log("my parent of Move unit", myParentOfMoveUnit);
      // console.log("myDestination parent unit", myDestinationParentUnit);

      if (myParentOfMoveUnitRef && myDestinationParentUnitRef) {
        myDestinationParentUnitRef.children.push(myMoveUnitRef);
        myParentOfMoveUnitRef.children.splice(
          myParentOfMoveUnitRef.children.indexOf(myMoveUnitRef),
          1,
        );
        chart = chart;

        try {
          // /{clientUuid}/structure{orgChartUuid}/unit/{unitUuid/transfer/{underParentUuuid}

          const res = await $axiosAuth.put(
            "/" +
              clientUuid +
              "/structure/" +
              $params.chart +
              "/unit/" +
              moveUnit.uuid +
              "/transfer/" +
              destinationParentUnit.uuid,
          );
        } catch (error) {
          console.error(error);
          alert(JSON.stringify(error));
        } finally {
          rearranging = false;
          openRearrange = false;
        }
      }
    }
  }

  let openConfirmDeleteChartDialog = false;

  function confirmDeleteChart(event) {
    openConfirmDeleteChartDialog = true;
  }

  function cancelDeleteChart(event) {
    deleteConfirmation = "";
    openConfirmDeleteChartDialog = false;
  }

  let deleteName = "department name";

  function openConfirmDeleteDialog(event) {
    deleteName = event.detail.name;
    open = true;
  }

  async function getChart(clientUuid) {
    try {
      // /{clientUuid}/structure/{orgChartUuid}
      const res = await $axiosAuth.get(
        "/" + clientUuid + "/structure/" + $params.chart,
      );
      chart = await res.data;
      // alert(JSON.stringify(res));
    } catch (error) {
      throw new Error(error);
      console.log(error);
      console.log(res);
    }
  }

  let clientUuid = 0;

  $: if ($params.chart) {
    clientUuid = $commons.orgUuid;

    if (clientUuid) {
      let promise = getChart(clientUuid);
    }
  }
</script>

<svelte:window on:click={closeDeleteMenu} />

{#if chart}
  <div class="level">
    <div class="level-left">
      <div class="level-item">
        <ChartsMenu />
      </div>
      <div class="level-item">
        <button
          class="button"
          on:click={openRearrangeDialog}
          title="Αναδιάταξη..."
        >
          <!-- move vertical icon -->
          <span class="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <polyline points="8 18 12 22 16 18" />
              <polyline points="8 6 12 2 16 6" />
              <line x1="12" y1="2" x2="12" y2="22" />
            </svg>
          </span>
        </button>
      </div>
      <div class="level-item">
        <a
          class="button"
          target="_blank"
          href={$url("../print")}
          title="Εκτύπωση..."
        >
          <span class="icon">
            <PrinterIcon size="1.5x" />
          </span>
        </a>
      </div>

      <div class="level-item">
        <div
          class="dropdown"
          class:is-active={deleteMenu}
          style="min-width: 100px;"
        >
          <div class="dropdown-trigger">
            <button
              class="button"
              on:click={(event) => {
                event.stopPropagation();
                deleteMenu = true;
              }}
            >
              <span class="icon">
                <MoreHorizontalIcon size="1.5x" />
              </span>
            </button>
          </div>
          <div class="dropdown-menu" id="dropdown-menu" role="menu">
            <div class="dropdown-content">
              <a href="#" class="dropdown-item" on:click={confirmDeleteChart}>
                Διαγραφή...
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="columns columns-reverse-mobile">
    <div class="column column-reverse-mobile">
      <ul class="menu-list">
        <li>
          <Unit unit={chart} on:new={askForNewUnit} />
        </li>
      </ul>
      <div style="height:20rem" />
    </div>
    {#if $params.department != "all"}
      <div class="column is-half column-reverse-mobile">
        <div class="sticky">
          <Department
            bind:this={selectedDepartment}
            chart={$params.chart}
            department={$params.department}
            on:delete={openConfirmDeleteDialog}
            on:assign={openAssignDialog}
          />
        </div>
      </div>
    {/if}
  </div>
{:else}
  <div in:fade|local class="columns" style="padding:2rem">
    <div style="margin-top:5rem">
      <Spinner size="40" speed="850" thickness="2" gap="40" />
    </div>
  </div>
{/if}

<!-- delete chart dialog -->
{#if openConfirmDeleteChartDialog}
  <div class="modal" class:is-active={openConfirmDeleteChartDialog}>
    <!-- on:click={cancelDeleteChart}  -->
    <div out:fade|local class="modal-background" />
    <div transition:fly|local={{ duration: 250, y: 10 }}  class="box modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <!-- Delete -->
          Διαγραφή ολόκληρου του οργανογράμματος;
        </p>
        <button
          class="delete"
          on:click={cancelDeleteChart}
          aria-label="close"
        />
      </header>
      <section class="modal-card-body">
        <p>Η διαγραφή οργανογράμματος είναι μη αναστρέψιμη ενέργεια.</p>
        <p>
          Επιβεβαιώστε την διαγραφή πληκτρολογώντας <b
            >διαγραφή οργανογράμματος</b
          >
        </p>
        <hr />
        <div class="field">
          <p class="control">
            <input
              autofocus
              class="input"
              type="text"
              placeholder=""
              bind:value={deleteConfirmation}
            />
          </p>
        </div>
      </section>
      <footer class="modal-card-foot">
        {#if deleteConfirmation === "διαγραφή οργανογράμματος"}
          <button
            on:click={deleteChart}
            class="button is-pulled-left is-danger is-light"
          >
            <Trash2Icon size="1.5x" />
            <!-- &nbsp; Delete -->
            &nbsp; Διαγραφή
          </button>
        {/if}
        <button on:click={cancelDeleteChart} class="button is-light pull-right">
          <!-- Cancel -->
          Ακύρωση
        </button>
      </footer>
    </div>
  </div>
{/if}

<!-- delete dialog -->
{#if open}
  <div class="modal" class:is-active={open}>
    <div out:fade|local on:click={cancel} class="modal-background" />
    <div transition:fly|local={{ duration: 250, y: 10 }}  class="box modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <!-- Delete -->
          Διαγραφή
        </p>
        <button class="delete" on:click={cancel} aria-label="close" />
      </header>
      <section class="modal-card-body">
        Διαγραφή {deleteName};

        <div class="notification is-danger is-light">
          Η διαγραφή οργανωτικής μονάδας ενδέχεται να προκαλέσει σοβαρό
          πρόβλημα σε παραγωγική εγκατάσταση.
          <br />
          <br />
          Σας προτρέπουμε να απενεργοποιήσετε την οργανωτική μονάδα αντί να τη διαγράψετε.
          <br />
          <br />
          Προχωρήστε σε διαγραφή μόνον αν το οργανόγραμμα δεν βρίσκεται σε παραγωγική
          λειτουργία ή επικοινωνήστε με την ομάδα υποστήριξης.
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          on:click={deleteDepartment}
          class="button is-pulled-left is-danger is-light"
        >
          <Trash2Icon size="1.5x" />
          <!-- &nbsp; Delete -->
          &nbsp; Διαγραφή
        </button>
        <button on:click={cancel} class="button is-light pull-right">
          <!-- Cancel -->
          Ακύρωση
        </button>
      </footer>
    </div>
  </div>
{/if}

<svelte:head>
  {#if openAssign}
    <style>
      body {
        overflow: hidden;
      }
    </style>
  {/if}
</svelte:head>

<!-- rearrange dialog -->

{#if openRearrange}
  <div class="modal" class:is-active={openRearrange}>
    <div out:fade|local on:click={cancelRearrange} class="modal-background" />
    <div transition:fly|local={{ duration: 250, y: 10 }}  class="box modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Αναδιάταξη</p>
        <button class="delete" on:click={cancelRearrange} aria-label="close" />
      </header>
      <section class="modal-card-body">
        <div class="notification is-danger is-light">
          Η αναδιάταξη οργανωτικών μονάδων ενδέχεται να προκαλέσει σοβαρό
          πρόβλημα σε παραγωγική εγκατάσταση.
          <br />
          <br />
          Σας προτρέπουμε να δημιουργήσετε νέα οργανωτική μονάδα επιλέγοντας "Μετονομασία, μετακίνηση
          ή συγχώνευση" 
          <br />
          και να ορίσετε την οργανωτική μονάδα προέλευσης.
          <br />
          <br />
          Στη συνέχεια μπορείτε να απενεργοποιήσετε την οργανωτική μονάδα προέλευσης.
          <br />
          <br />
          Προχωρήστε σε αναδιάταξη μόνον αν το οργανόγραμμα δεν βρίσκεται σε παραγωγική
          λειτουργία ή επικοινωνήστε με την ομάδα υποστήριξης.
        </div>
        <div class="field">
          <label class="label">Η οργανωτική μονάδα</label>
          <div class="control">
            <Assign type="unit" bind:selectedItem={moveUnit} />
          </div>
        </div>
        <div class="field">
          <label class="label">να τοποθετηθεί κάτω από την</label>
          <div class="control">
            <Assign type="unit" bind:selectedItem={destinationParentUnit} />
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          disabled={!moveUnit || !destinationParentUnit}
          on:click={rearrange}
          class:is-loading={rearranging}
          class="button is-pulled-left is-link"
        >
          &nbsp; Αναδιάταξη
        </button>
        <button on:click={cancelRearrange} class="button is-light pull-right">
          <!-- Cancel -->
          Ακύρωση
        </button>
      </footer>
    </div>
  </div>
{/if}

<!-- assign dialog -->
{#if openAssign}
  <div class="modal" class:is-active={openAssign}>
    <div out:fade|local class="modal-background" />
    <div
      transition:fly|local={{ duration: 250, y: 10 }} 
      class="box modal-card"
      class:width-height-lg={selectedPeople &&
        selectedPeople.length &&
        selectedPeople.length > 1 &&
        selectedPeople.length < 3}
      class:width-height-65={selectedPeople &&
        selectedPeople.length &&
        selectedPeople.length > 2 &&
        selectedPeople.length < 4}
      class:width-height-75={selectedPeople &&
        selectedPeople.length &&
        selectedPeople.length > 3 &&
        selectedPeople.length < 6}
      class:width-height-85={selectedPeople &&
        selectedPeople.length &&
        selectedPeople.length > 5 &&
        selectedPeople.length < 8}
      class:width-height-100={selectedPeople &&
        selectedPeople.length &&
        selectedPeople.length > 7}
    >
      <header class="modal-card-head">
        <p class="modal-card-title">Νέα ανάθεση</p>
        <button class="delete" on:click={cancelAssign} aria-label="close" />
      </header>
      <section class="modal-card-body">
        <div class="field">
          <label class="label">Οργανόγραμμα</label>
          <div class="control">
            <Assign type="chart" bind:selectedItem={selectedChart} />
          </div>
        </div>

        <div class="field">
          <label class="label">Τμήμα</label>
          <div class="control">
            <Assign type="unit" bind:selectedItem={selectedUnit} />
          </div>
        </div>

        <div class="field">
          <label class="label">Ρόλος</label>
          <div class="control">
            <Assign type="role" bind:selectedItem={selectedRole} />
          </div>
        </div>

        <!-- <div class="field">
          <label class="label">Πρόσωπο</label>
          <div class="control">
            <Assign type="person" bind:selectedItem={selectedPerson} />
          </div>
        </div> -->

        <div class="field">
          <label class="label"
            >Πρόσωπα {selectedPeople && selectedPeople.length
              ? selectedPeople.length
              : ""}</label
          >
          <div class="control">
            <Select
              items={simplePeople}
              isMulti
              noOptionsMessage="Δεν βρέθηκαν πρόσωπα"
              bind:selectedValue={selectedPeople}
              optionIdentifier="uuid"
              getOptionLabel={(item) => labelFunction(item)}
              getSelectionLabel={(item) => labelFunction(item)}
              itemFilter={(label, filterText, option) =>
                removeAccents(labelFunction(option)).includes(
                  removeAccents(filterText),
                )}
              placeholder="Αναζήτηση..."
            />
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          disabled={disableAdd}
          on:click={assign}
          class="button is-pulled-left is-link"
          class:is-loading={assigning}
        >
          &nbsp; Προσθήκη
        </button>
        <button on:click={cancelAssign} class="button is-light pull-right">
          <!-- Cancel -->
          Ακύρωση
        </button>
      </footer>
    </div>
  </div>
{/if}

{#if openNewUnit}
  <div class="modal" class:is-active={openNewUnit}>
    <div out:fade|local on:click={cancelNewUnit} class="modal-background" />
    <div transition:fly|local={{ duration: 250, y: 10 }}  class="box modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Νέα οργανωτική μονάδα</p>
        <button class="delete" on:click={cancelNewUnit} aria-label="close" />
      </header>
      <section class="modal-card-body">
        <div class="field">
          <label class="label">Όνομα</label>
          <div class="control">
            <input
              type="text"
              bind:value={newUnitName}
              placeholder=""
              class="input"
              autofocus
            />
          </div>
        </div>

        <!-- <div>{JSON.stringify(sourceUnits)}</div> -->

        <!-- check if backend version supports unit sources -->
        {#if newUnitDisabled == true || newUnitDisabled == false}
          <hr />
          <label class="checkbox">
            <input type="checkbox" bind:checked={newUnitHasSource} />
            Μετονομασία, μετακίνηση ή συγχώνευση
          </label>
        {/if}

        {#if newUnitHasSource}
          <hr />
          {#each sourceUnits as s, i}
            <div class="field">
              <label class="label">
                {#if sourceUnits.length > 1}{i + 1}.{/if}
                Οργανωτική μονάδα προέλευσης
              </label>
              <div class="control">
                <div class="level">
                  <Assign type="unit" bind:selectedItem={s} />
                  <div class="is-flex-right">
                    {#if sourceUnits.length > 1}
                      <button
                        title="Αφαίρεση"
                        class="button is-light"
                        on:click={removeSourceUnit(s)}
                      >
                        <XIcon size="1x" />
                      </button>
                    {/if}
                  </div>
                </div>
              </div>
            </div>
          {/each}
          <button class="button is-light" on:click={addSourceUnit}>
            <PlusIcon size="1.5x" />
            &nbsp; Οργανωτική μονάδα προέλευσης
          </button>

          <hr />
          <label class="checkbox">
            <input type="checkbox" bind:checked={newUnitTransferAssignments} />
            Να γίνει αντιγραφή των αναθέσεων στη νέα οργανωτική μονάδα
          </label>
        {/if}
      </section>
      <footer class="modal-card-foot">
        <button on:click={addNewUnit} class="button is-pulled-left is-link">
          &nbsp; Προσθήκη
        </button>
        <button on:click={cancelNewUnit} class="button is-light pull-right">
          <!-- Cancel -->
          Ακύρωση
        </button>
      </footer>
    </div>
  </div>
{/if}

<style>
  @media all and (min-width: 769px) {
    .width-height-lg {
      width: 55%;
      height: 55%;
      transition:
        width 300ms ease,
        height 300ms ease;
    }

    .width-height-65 {
      width: 65%;
      height: 65%;
      transition:
        width 300ms ease,
        height 300ms ease;
    }

    .width-height-75 {
      width: 75%;
      height: 75%;
      transition:
        width 350ms ease,
        height 350ms ease;
    }

    .width-height-85 {
      width: 85%;
      height: 85%;
      transition:
        width 400ms ease,
        height 400ms ease;
    }

    .width-height-100 {
      width: 100%;
      height: 100%;
      transition:
        width 400ms ease,
        height 400ms ease;
      overflow: auto !important;
    }
  }

  @media (max-height: 768px) {
    .modal-card,
    .width-height-lg,
    .width-height-65,
    .width-height-75,
    .width-height-85 {
      width: 100%;
      height: 100%;
      transition:
        width 400s ease,
        height 400s ease;
      overflow-y: auto !important;
    }
  }

  .modal-card-body {
    overflow: visible;
  }

  @media (max-width: 768px) {
    :global(.columns-reverse-mobile) {
      flex-direction: column-reverse;
      display: flex;
    }

    :global(.column-reverse-mobile) {
      width: 100%;
    }
  }

  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 7rem;
  }
</style>
