<script>
  import { charts } from "../corpjobperson.js";
  import { url, goto, params } from "@sveltech/routify";
  import { scale, fade, fly } from "svelte/transition";
  import { ChevronDownIcon } from "svelte-feather-icons";
  import { findUnitName } from "../utils.js";

  let open = false;
  const cancel = () => (open = false);

  const edit = () => {
    open = false;
    $goto("/structures");
  };

  const selectChart = (id) => {
    open = false;
    $goto("/structure/:chart/department/all", {
      chart: id,
    });
    // $url('./', {paramToBeChanged: 123})
  };

  // function selectedChartName(id) {
  //   let selectedChart = $charts.filter(chart => chart.uuid === id);

  //   if (selectedChart.length) {
  //     return selectedChart[0].name;
  //   } else {
  //     return null;
  //   }
  // }

  $: selectedName =
    $charts.length && $params.chart
      ? findUnitName($params.chart, $charts)
      : null;
</script>

{#if selectedName}
  <button on:click={() => (open = true)} class="button is-large">
    <span>{selectedName}</span>
    <span class="icon is-small" style="margin-top:6px">
      <ChevronDownIcon size=".8x" />
    </span>
  </button>
{:else}{/if}
<!-- select structure modal -->
{#if open}
  <div class="modal" class:is-active={open}>
    <div out:fade|local on:click={cancel} class="modal-background" />
    <div transition:fly|local={{ duration: 250, y: 10 }}  class="box modal-card" style="padding:0">
      <header class="modal-card-head">
        <p class="modal-card-title">Οργανογράμματα</p>
        <button class="delete" on:click={cancel} aria-label="close" />
      </header>
      <section class="modal-card-body">
        <ul class="menu-list">
          {#each $charts as chart}
            <li>
              <a on:click={() => selectChart(chart.uuid)}>{chart.name}</a>
            </li>
          {/each}
        </ul>
      </section>
      <footer class="modal-card-foot">
        <button on:click={cancel} class="button is-light pull-right">
          Άκυρο
        </button>
        <button on:click={edit} class="button is-light pull-right">
          Επεξεργασία
        </button>
      </footer>
    </div>
  </div>
{/if}

<style>
  :global(.modal) {
    z-index: 100000 !important;
    position: fixed;
    overflow: auto;
  }

  :global(.modal-card-body){
    overflow:visible !important;
  }

  :global(.modal-card) {
    z-index: 100001 !important;
    overflow: visible !important;
    padding: 0 !important;
  }

  :global(.modal-background) {
    position: fixed;
  }
  :global(.modal-content) {
    overflow: visible;
  }
  
</style>
