import { createServiceInstance, getAxiosInstance, getLoginDetails, keycloakLogin, keycloakLogout } from "./keyCloakService.js";


import {
    profile,
    commons,
    axiosAuth,
    loggedIn,
    waiting,
    errorWithSSO,
    errorWithCustomInfo,
    errorWithCustomInfoAttributes
} from "./auth.js";

//  openonesso_scope: customInfo.openonesso_scope,
//  openonesso_authority: customInfo.openonesso_authority,
//  orgId: customInfo.orgId,
//  orgUuid: customInfo.orgUuid,
//  ldap: ldap

//TODO useful for other apps except ORG
// console.log("Getting user info from org....");
// get user info from SSO

// let secondsUntilRefresh = Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000);

const defaults = {
    org_url: window.env ? window.env.BASE_URL : __process.env.BASE_URL,
    keycloak_url: window.env ? window.env.KEYCLOAK_URL : __process.env.KEYCLOAK_URL,
    keycloak_realm: window.env ? window.env.KEYCLOAK_REALM : __process.env.KEYCLOAK_REALM,
    keycloak_client_id: window.env ? window.env.KEYCLOAK_CLIENT_ID : __process.env.KEYCLOAK_CLIENT_ID
}



export const loginKEYCLOAK = async () => {
    try {
        createServiceInstance(defaults);
        await keycloakLogin();
    } catch (error) {
        loggedIn.set(false);
    }
}

export const logoutKEYCLOAK = () => {
    keycloakLogout();
}


export const authenticateKEYCLOAK = async () => {


    try {


        waiting.set(true);

        await createServiceInstance(defaults);

        let authenticated = await keycloakLogin('check-sso');

        if (authenticated) {

            let details = await getLoginDetails();

            if (details && details.keycloakUserInfo) {


                profile.set({
                    userInfo: { name: details.keycloakUserInfo.preferred_username },
                    customInfo: { "org": details.keycloakUserInfo.orgName[0] }
                });

                commons.set({
                    openonesso_scope: "tmp",
                    openonesso_authority: "tmp",
                    orgId: details.orgUserInfo.id,
                    orgUuid: details.orgUserInfo.uuid,
                    ldap: false
                });

            } else {
                errorWithSSO.set(true);
                errorWithCustomInfo.set(true);

                setTimeout(() => { logoutKEYCLOAK(); }, 10000);
            }

            let axiosInstance = await getAxiosInstance();

            axiosAuth.set(axiosInstance);

            loggedIn.set(true);
        }


    } catch (error) {

        console.error("auth keycloak error: ", error);

    }

    waiting.set(false);
};